<template>
  <section id="knowledge-base-list">
    <div v-if="options.length !== 0">
      <!-- collapse -->
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
      >

        <app-collapse-item
          v-for="( data,index) in options"
          :key="index"
          :title="data.title"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="mb-50"
            v-html="data.message"
          />
          <!-- Label Row -->
          <div class="email-label">
            <b-badge
              v-for="(label,idx) in data.tags"
              :key="idx"
              pill
              class="text-capitalize mr-50"
              variant="light-primary"
            >
              {{ label.name }}
            </b-badge>
          </div>
        <!-- eslint-enable -->
        </app-collapse-item>

      </app-collapse>

    <!--/ collapse -->
    </div>
    <div v-else>
      <h6 class="mt-1">
        No Data Found
      </h6>
    </div>
  </section>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BBadge,
    AppCollapseItem,
    AppCollapse,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
