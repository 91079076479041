<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
      >
        <b-card-body class="text-center knw">
          <h2 class="text-primary">
            Let's answer some questions
          </h2>
          <b-card-text class="mb-2">
            or choose a category to quickly find the help you need
          </b-card-text>

          <!-- form -->
          <b-form
            class="faq-search-input"
            @submit.prevent="fetchData"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search knowledge base..."
              />
              <b-input-group-append v-if="faqSearchQuery">
                <b-button
                  variant="outline-secondary"
                  class="bg-white"
                  @click.prevent="clearSearchData"
                >
                  <feather-icon
                    icon="XIcon"
                    size="14"
                  />
                </b-button>
                <b-button
                  variant="outline-primary"
                  type="submit"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section
      id="faq-tabs"
      class="mb-2"
    >
      <b-row>
        <b-col cols="4">
          <b-list-group>
            <b-list-group-item
              :to="{ name: 'knowledge' }"
              :active="isDynamicRouteActive({ name: 'knowledge' })"
              class="text-capitalize d-flex"
            >
              <span class="mr-1">
                <feather-icon
                  icon="ListIcon"
                  size="16"
                />
              </span>
              <span>Recent Update</span>
            </b-list-group-item>

            <b-list-group-item
              v-for="data in tagData"
              :key="data.tagging_id"
              :active="isDynamicRouteActive({ name: 'knowledge-label', params: { label: data.tagging_id } })"
              :to="{ name: 'knowledge-label', params: { label: data.tagging_id } }"
              class="text-capitalize d-flex"
            >
              <span class="mr-1">
                <feather-icon
                  icon="TagIcon"
                  size="16"
                />
              </span>
              <span>{{ data.name }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="8">
          <faq-question-answer :options="faqData" />

          <div class="m-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="dataMeta.total"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="onPageChange"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-col>
      </b-row>
    </section>
    <!--/ frequently asked questions tabs pills -->

  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BRow, BCol,
  BListGroup, BListGroupItem, BInputGroupAppend, BButton, BPagination,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { isDynamicRouteActive } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BButton,
    BInputGroupPrepend,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
    BPagination,
    FaqQuestionAnswer,
  },
  data() {
    return {
      tagData: {},
      currentPage: 1,
      perPage: 25,
      faqSearchQuery: '',
      faqData: [],
      dataMeta: {
        from: '',
        to: '',
        total: '',
      },
      roleFilter: '',
      prevRoleFilter: '',
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      isDynamicRouteActive,
    }
  },
  created() {
    this.getTagData()
    this.fetchData()
    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (toParams, previousParams) => {
        if (toParams.label) {
          this.faqSearchQuery = ''
          this.roleFilter = {
            column: 'tagging_id',
            value: Number(toParams.label),
          }
        } else {
          this.roleFilter = ''
        }
        this.fetchData()
      },
    )
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.fetchData()
    },
    clearSearchData() {
      this.faqSearchQuery = ''
      this.fetchData()
    },
    fetchData() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        length: this.perPage,
        filters: [],
        sort_by: [{
          column: 'created_at',
          asc_desc: 'asc',
        }],
      }
      if (this.faqSearchQuery !== '') {
        this.$router.replace({ name: 'knowledge' }).catch(() => {})
        params.keyword = this.faqSearchQuery
      }
      let target = '/knowledge'
      if (this.roleFilter) {
        params.filters.push(this.roleFilter)
      }
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.faqData = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Knowledge base data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getTagData() {
      this.$http.post('ref/tg', true, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.tagData = resp.data
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Tag list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
